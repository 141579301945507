/*
 * CustomDataTable.tsx
 * Author: Frank Wunderlich
 * Date: 07.02.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {DataTable, DataTableSortOrderType} from 'primereact/datatable';
import {Column, ColumnSortParams} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {Ripple} from 'primereact/ripple';
import {classNames} from 'primereact/utils';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import './../../style/override.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ProgressSpinner} from 'primereact/progressspinner';
import {getUserFromLocalStorage, setUserSettings} from '../functions/Global';
import {faEye, faEyeSlash, faSlash} from '@fortawesome/free-solid-svg-icons';
import {faCircleCheck, faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import {useParams} from 'react-router-dom';
import {Skeleton} from 'primereact/skeleton';
import {CustomConfirmDialog} from './CustomConfirmDialog';
import CustomDialog from './CustomDialog';
import Input from './Input';
import keycloakfetch from "../../functions/keycloakfetch";
import settings from "../../config/settings";
import {
    useGetAnredeQuery,
    useGetBearbeiterQuery,
    useGetIafCodesQuery,
    useGetIsoScopesQuery,
    useGetKompetenzQuery, useGetKritikalitaetQuery,
    useGetKundeQuery, useGetProfitcenterQuery,
    useGetStatusbearbeitungQuery,
    useGetTitleQuery,
    useLazySearchFilesQuery
} from "../../redux/rtk/injectedPersonApi";
import StatusLight from "./StatusLight";
import {Tooltip} from "primereact/tooltip";

const moment = require('moment-timezone');

type Props = {
    id: string,
    columns: ColumnObject[],
    editable: boolean,
    sortable: boolean,
    value: any[], // data
    header?: any,
    headerTitle?: string,
    className?: string,
    maxCharacters?: number,
    selection?: any,
    selectionMode?: any,
    scrollHeight?: string,
    style?: any,
    tableId?: string,
    ableToDelete?: boolean,
    ableToUpdate?: boolean,
    customButtons?: CustomButton[],
    buttonToolTipEdit?: string,
    buttonToolTipDelete?: string,
    onEditClick?: (e: any) => void,
    onRemoveClick?: (e: any) => void,
    onRowEditComplete?: (e: any) => void,
    onRowClick?: (e: any) => void,
    onSelectionChange?: (e: any) => void,
    filters?: any,
    onFilterChanged?: (e: any) => void,
    showLoading?: boolean,
    paginator?: boolean,
    paginatorHideAll?: boolean,
    // Grouping
    rowGroupMode?: string,
    groupRowsBy?: string,
    expandable?: boolean,
    expandableRowGroups?: boolean, // Aufklappbar oder nicht
    expandedRows?: any,
    scrollToFirstExpaned?: boolean,
    rowGroupHeaderButtons?: CustomButton[],
    // expandedRows?: any, // alle aufgeklappten Gruppen
    // setExpandedRows?: (e: any) => any,
    onRowToggle?: (e: any) => any,
    onRowExpand?: (e: any) => any,
    onRowCollapse?: (e: any) => any,
    rowGroupHeaderTemplate?: (e: any) => any,
    rowGroupFooterTemplate?: (e: any) => any
    dataloaded?: boolean

    // Ordering
    reorderableRows?: boolean,
    onRowReorder?: (e: any) => any

    // Column Styling
    rowClass?: RowClass[],
    displayColumnFilter?: boolean,

    // Edit
    editMode?: string,

    filterOptionSelected?: (values: any) => void,
    filterOptionsField?: string
    selectedFilters?: (values: any) => void,
    hideSearch?: boolean,
    timerangefield?: boolean,
    onTimeRangeSelect?: (values: any) => void,

    // Global Filter
    hideGlobalFilter?: boolean,
    showGlobalFilterButton?: boolean,

    // Skeleton Loading
    skeletonCount?: number;

    // Sorting
    sortField?: string;
    sortOrder?: DataTableSortOrderType;

    // Exporting
    showExportCSV?: boolean
}

export type ColumnObject = {
    field: string,
    header: string,
    className?: string,
    type?: ColumnObjectType,
    options?: any[],
    body?: any,
    filter?: boolean,
    filterDropdownValues?: any[],
    filterDropdownField?: string,
    filterField?: string,
    filterElement?: (options: any) => void,
    showFilterMenu?: boolean,
    showFilterMatchModes?: boolean,
    style?: any,
    onClick?: (e: any) => void,
    rowOrder?: boolean,
    display?: boolean,
    editCell?: (e: any) => void,
    editCellClassName?: string,
    editCellStyle?: string,
    sortFunction?: (sort: ColumnSortParams) => void,
    dateFormat?: string     // Für Datumsspalten (type=4), kann bei Abweichung vom Default angegeben werden
}


export type CustomButton = {
    // icon: any,
    onClick: (data: any) => void;
    tooltip?: string,
    tooltipOptions?: any,
    className?: string,
    icons: any[],
    permitted: boolean // permissions check
}

export enum ColumnObjectType {
    Text,
    Number,
    Checkbox,
    Dropdown,
    Date,
    Buttons,
    Image,
    Boolean,
    StatusLights
}

export type RowClass = {
    key: string,
    value: any,
    class: string,
}

const CustomDataTable = (props: Props): JSX.Element => {

        const tableRef = useRef<any>(null);

        const [width, setWidth] = React.useState(window.innerWidth);
        const breakpoint = 960;

        useEffect(() => {
            window.addEventListener('resize', () => setWidth(window.innerWidth));
        }, []);


        const skeletonValues = [];
        const count = props.skeletonCount ? props.skeletonCount : 10;

        for (let i = 0; i < count; i++) {
            skeletonValues.push([]);
        }

        const params = useParams();
        const {t} = useTranslation(['common', 'customDataTable']);

        const user_string = localStorage.getItem('user');
        let uuf = false;
        if (user_string) {
            uuf = JSON.parse(user_string).is_saving_userfilters_enabled;
        }


        const [childKey, setChildKey] = useState(1);
        const [copy, setCopy] = useState<any[]>([]);
        const [globalFilter, setGlobalFilter] = useState(null);
        const [columnFilters, setColumnFilters] = useState<any>({});
        const [columnDisplayFilters, setColumnDisplayFilters] = useState<any>({});
        const [usedFilters, setUsedFilters] = useState<any>({});
        const [filters, setFilters] = useState<any>({});
        const [pageRows, setPageRows] = useState<number>(10);
        const [first, setFirst] = useState<number>(0);
        const [filterTemp, setFilterTemp] = useState<any>(undefined);
        const [expandedRows, setExpandedRows] = useState<any>(null);
        const [scrollToFirstExpanedTriggered, setScrollToFirstExpanedTriggered] = useState<boolean>(false);

        const [useUserFilters, setUseUserFilters] = useState<boolean>(uuf);
        const [filterVals, setFilterVals] = useState<any>({});
        const [dates, setDates] = useState<any>(null);
        const [showSkeleton, setShowSkeleton] = useState<any>(true);
        // console.log('props', props);
        // const [makeCopy, setMakeCopy] = useState(true);

        const [isMobile, setIsMobile] = useState<boolean>(false);

        const [sortField, setSortField] = useState<any>(undefined);
        const [sortOrder, setSortOrder] = useState<any>(undefined);

        const [scrollEvent, setScrollEvent] = useState<any>(undefined);

        const [showGlobalFilterDialog, setShowGlobalFilterDialog] = useState<boolean>(false);
        const [globalFilterDialogValues, setGlobalFilterDialogValues] = useState<any[]>([]);
        const [preventGlobalChange, setPreventGlobalChange] = useState<boolean>(false);

        const [showSearchFileDialog, setShowSearchFileDialog] = useState<boolean>(false);
        const [searchFileContent, setSearchFileContent] = useState<any>(undefined);
        const [pdfSearchText, setPdfSearchText] = useState<string>('');
        const [pdfSearchConnectionAnd, setPdfSearchConnectionAnd] = useState<boolean>(false);
        const [selectedPdf, setSelectedPdf] = useState<any>(undefined);
        const [changedValue, setChangedValue] = useState<any>(null);

        const buttonIconWidth = 35;
        const customButtonWidth = 32;
        const buttonColumnWidth = (props.ableToUpdate ? buttonIconWidth : 0)
            + (props.ableToDelete && (typeof props.selectionMode === 'undefined' || props.selectionMode === 'single') ? buttonIconWidth : 0)
            + (props.customButtons ? props.customButtons.filter(item => {
                return item.permitted;
            }).length * customButtonWidth : 0);

        const [triggerSearchFiles, resultSearchFiles, lastPromiseSearchFiles] = useLazySearchFilesQuery();

        const showPaginator = (): boolean => {
            return typeof props.paginator === 'boolean' ? props.paginator : props.value.length > 100;
        };

        useEffect(() => {
            setIsMobile(breakpoint >= width);
        }, [width]);

        useEffect(() => {
            setChildKey(prev => prev + 1);
        }, [isMobile]);

        // useEffect(() => {
        //     console.log('cv', changedValue)
        // }, [changedValue]);

        useEffect(() => {
            for (const item in props.filters) {
                if (usedFilters[item]) {
                    props.filters[item].value = usedFilters[item].value;
                }
            }

            setFilters(props.filters);

            document.removeEventListener('scroll', onScroll, true);
            setScrollEvent(document.addEventListener('scroll', onScroll, true));

            return () => {
                document.removeEventListener('scroll', onScroll, true);
                setScrollEvent(undefined);
            };
        }, []);

        useEffect(() => {
            setExpandedRows(props.expandedRows);
        }, [props.expandedRows]);

        useEffect(() => {
            if (props.value) {

                const pRows = localStorage.getItem('pageRows_' + props.id);
                if (pRows) {
                    setPageRows(parseInt(pRows));
                }

                // let makeCopy = true;
                //
                // const idKey = props.tableId || 'Id';
                // if (copy.length) {
                //     const valueIds = props.value.map((x: any) => {
                //         return x[idKey];
                //     });
                //     const copyIds = copy.map((x: any) => {
                //         return x[idKey];
                //     });
                //
                //     if (valueIds.length === copyIds.length && valueIds.every(function (value, index) {
                //         return value === copyIds[index];
                //     })) {
                //         makeCopy = false;
                //     }
                // }
                //
                // if (makeCopy) {
                //     console.log('make copy: ', props.value);
                //     console.log('make copy: ', !!props.value);
                //     setCopy(JSON.parse(JSON.stringify(props.value)));
                // }
                // setMakeCopy(false);
            }
        }, [props.value]);

        useEffect(() => {
            if (props.dataloaded === true) {
                setShowSkeleton(false);
            }
        }, [props.dataloaded]);

        const onScroll = (e: any) => {
            if (tableRef.current) {
                const element = tableRef.current.getElement();
                if (element.classList.contains('p-datatable')) {

                    const all = element.querySelectorAll('table tbody tr');
                    const expanded = element.querySelectorAll('table tbody tr+tr.p-datatable-row-expansion');

                    for (let i = 0; i < all.length; i++) {
                        all[i].classList.remove('fixed-row');
                        all[i].style.top = undefined;
                    }

                    for (let i = 0; i < expanded.length; i++) {
                        expanded[i].style.marginTop = 'inherit';
                    }

                    const tableWrapper = element.querySelector('.p-datatable-wrapper');


                    const thead = tableWrapper.querySelector('table thead');

                    const scrollTop = tableWrapper.scrollTop;

                    for (let i = 0; i < expanded.length; i++) {
                        const exp = expanded[i];
                        const prev = exp.previousElementSibling;
                        const pos = prev.offsetTop - thead.clientHeight;

                        const addOffset = 12;

                        if (prev.offsetTop - prev.clientHeight < scrollTop + addOffset && exp.offsetTop + exp.clientHeight > scrollTop) {
                            let posPrev = thead.clientHeight + scrollTop;
                            const posExp = prev.clientHeight;

                            if (scrollTop > (exp.offsetTop + exp.clientHeight) - (prev.clientHeight + thead.clientHeight)) {
                                // console.log(scrollTop, (exp.offsetTop + exp.clientHeight) - (prev.clientHeight + thead.clientHeight) - scrollTop);
                                const offset = (exp.offsetTop + exp.clientHeight) - (prev.clientHeight + thead.clientHeight) - scrollTop;
                                posPrev += offset;
                                // posExp += offset;
                            }

                            prev.classList.add('fixed-row');
                            prev.style.top = posPrev + 'px';
                            exp.style.marginTop = posExp + 'px';
                        }
                    }
                }
            }
        };

        useEffect(() => {
            if (tableRef.current) {
                if (!scrollToFirstExpanedTriggered && !showSkeleton) {
                    if (props.scrollToFirstExpaned) {
                        const exp = tableRef.current.getElement().getElementsByClassName('p-datatable-row-expansion');

                        if (exp.length > 0) {
                            exp[0].previousElementSibling.scrollIntoView();
                        }

                        setScrollToFirstExpanedTriggered(true);
                    }
                }
            }
        }, [tableRef.current]);

        useEffect(() => {
            if (preventGlobalChange) {
                setPreventGlobalChange(false);
            } else if (globalFilterDialogValues.length > 0) {
                setFilters((prevState: any) => {
                    let temp: any = {...prevState};

                    for (let i = 0; i < globalFilterDialogValues.length; i++) {
                        let item = globalFilterDialogValues[i];

                        if (temp[item.name] && temp[item.name].constraints && temp[item.name].constraints.length > 0) {
                            temp[item.name].constraints[0].value = item.value;
                        }
                    }

                    return temp;
                });
            }
        }, [globalFilterDialogValues]);

        const [selectedColumns, setSelectedColumns] = useState<ColumnObject[]>([]);
        useEffect(() => {
            updateColumns();
        }, [props.columns]);

        const updateColumns = () => {
            const settings = getUserFromLocalStorage().settings;
            const displaySettings: any = settings['display_' + props.id];

            let tmp;

            if (displaySettings && displaySettings.length > 0) {
                tmp = props.columns.filter(col => displaySettings.indexOf(col.field) >= 0);
            } else {
                tmp = props.columns.filter(col => col.display === true || col.display !== false);
            }

            const columnOrder: string[] = settings['columnorder_' + props.id];

            if (columnOrder && columnOrder.length > 0) {
                tmp.sort((a: any, b: any) => {
                        return columnOrder.indexOf(a.field) > columnOrder.indexOf(b.field) ? 1 : -1;
                    }
                );
            }

            const sort = settings['sort_' + props.id];

            if (sort) {
                setSortField(sort.sortField);
                setSortOrder(sort.sortOrder);
            }

            if (props.showGlobalFilterButton) {
                let temp: any[] = [];
                for (let i = 0; i < props.columns.length; i++) {
                    let col = props.columns[i];

                    temp.push({name: col.field, value: ''});
                }
                setGlobalFilterDialogValues(temp);
            }

            setSelectedColumns(tmp);
        };

        const onColumnToggle = (event: any) => {
            const selectedColumns = event.value;
            const orderedSelectedColumns = props.columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field));
            //  console.log('orderedSelectedColumns', orderedSelectedColumns);
            setSelectedColumns(orderedSelectedColumns);

            clearTimeout(filterDelayTimer);
            filterDelayTimer = setTimeout(() => {
                setUserSettings('display_' + props.id, orderedSelectedColumns.map(item => item.field));
            }, 1500);
        };

        const anredeQuery = useGetAnredeQuery({});
        const titelQuery = useGetTitleQuery({});
        const bearbeiterQuery = useGetBearbeiterQuery({});
        // const kompetenzQuery = useGetKompetenzQuery({});
        // const kundeQuery = useGetKundeQuery({});
        // const languageQuery = useGetLanguageQuery({});
        const statusbearbeitungQuery = useGetStatusbearbeitungQuery({});
        // const isoScopesQuery = useGetIsoScopesQuery({});
        // const iafCodesQuery = useGetIafCodesQuery({});
        const profitcenterQuery = useGetProfitcenterQuery({});
        const kritikalitaetQuery = useGetKritikalitaetQuery({});

        const getTypeName = (types: any[], value: string, returnKey: string = 'name') => {
            let result = undefined;
            if (types && value) {
                result = types.find((item: any) => {
                    return item.id === value;
                });

                if (result) {
                    result = result[returnKey];
                }
            }
            return result;
        }

        const convertToCSV = (data: any) => {
            const rows = [];
            const csvSeparator = ';';

            // 1. Add header row
            let header = '';
            props.columns && selectedColumns.length > 0 && selectedColumns.map((column: ColumnObject) => {
                header += column.header + csvSeparator;
            });
            // Füge die Header-Zeile hinzu. Entferne aber das letzte Komma, da es überflüssig ist
            rows.push(header.slice(0, -1));

            // 2. Add data rows
            changedValue.forEach((row: any) => {
                // Filtere nur die Spalten, die im Objekt vorhanden sind
                const filteredColumns = selectedColumns.filter(column => Object.keys(row).includes(column.field));
                // Extrahiere die Werte in der Reihenfolge der gefilterten Spalten
                const values = filteredColumns.map(column => {
                    let result = '';
                    switch (column.field) {
                        case 'status_id':
                            result = getTypeName(statusbearbeitungQuery.data, row[column.field]);
                            break;
                        case 'bearbeiter_id':
                            result = getTypeName(bearbeiterQuery.data, row[column.field]);
                            break;
                        case 'anrede_id':
                            result = getTypeName(anredeQuery.data, row[column.field]);
                            break;
                        case 'titel_id':
                            result = getTypeName(titelQuery.data, row[column.field]);
                            break;
                        case 'betreuuendes_pc_ids':
                            result = getTypeName(profitcenterQuery.data, row[column.field]);
                            break;
                        case 'kritikalitaet_id':
                            result = getTypeName(kritikalitaetQuery.data, row[column.field]);
                            break;
                        case 'kompetenzenFilter':
                            result = row[column.field].label;
                            break;
                        default:
                            result = row[column.field];
                            break;
                    }
                    return typeof result == 'string' ? result.trim().replace(/(\r\n|\n|\r)/gm, "") : result;
                });

                // Erstelle die Semikolon-separierte Zeichenkette
                rows.push(values.join(csvSeparator));
            });

            return rows.join('\n');
        };

        const resetAllFilters = useCallback(() => {
            setGlobalFilterDialogValues((prevState: any) => {
                let temp = [...prevState];

                for (let i = 0; i < temp.length; i++) {
                    temp[i].value = '';
                }

                return temp;
            });
            setFilters(props.filters);
        }, [globalFilterDialogValues]);

        const exportCSV = () => {
            // eslint-disable-next-line new-cap
            CustomConfirmDialog({
                message: t('customDataTable:dialogs.exportAsCSVDialog.message'),
                header: t('confirmation'),
                translation: t,
                onConfirm: () => {
                    // if (tableRef) {
                    //     return tableRef.current.exportCSV();
                    // }

                    const data = convertToCSV(props.value);

                    // Create a Byte Order Mark, so that excel will use UTF-8 instead of ANSI for encoding
                    // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
                    const universalBOM = '\uFEFF';
                    const blob = new Blob([universalBOM + data], {type: 'text/csv;charset=utf-8;'});
                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', 'data.csv');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
        };

        const header = (
            <>
                {!props.hideSearch && <div className="table-header grid">
                    <div className="col p-0">
                        {props.header ? props.header : <h4 className="m-0 white-space-nowrap">{props.headerTitle}</h4>}
                    </div>
                    <div className="grid col-auto flex">
                        {props.displayColumnFilter === true &&
                            <div className={'col mr-3'}>
                                <div className="p-inputgroup">
                                    <MultiSelect
                                        value={selectedColumns}
                                        options={props.columns}
                                        optionLabel="header"
                                        onChange={onColumnToggle}
                                        style={{width: '20em'}}
                                        placeholder={t('customDataTable:visibleColumns')}
                                        fixedPlaceholder={true}/>
                                    <Button icon="pi pi-undo" className="p-button-secondary" onClick={() => {
                                        CustomConfirmDialog({
                                            header: t('dialogs.resetColumnsHeader'),
                                            message: t('dialogs.resetColumnsMessage'),
                                            translation: t,
                                            onConfirm: () => {
                                                setUserSettings('display_' + props.id, undefined);
                                                setUserSettings('columnorder_' + props.id, undefined);
                                                window.location.reload();
                                            }
                                        });
                                    }}/>
                                </div>
                            </div>}
                        {(props.hideGlobalFilter === undefined || props.hideGlobalFilter === false) &&
                            <div className="search-input col">
                                {props.timerangefield &&
                                    <Calendar value={dates} className="mr-2" dateFormat="dd.mm.yy" onChange={(e) => {
                                        setDates(e.value);
                                        props.onTimeRangeSelect && props.onTimeRangeSelect(e.value);
                                    }}
                                              selectionMode="range" readOnlyInput
                                              placeholder={t('conditionMonitoring:alarm_range')}/>}
                                <span className="p-input-icon-left">
                                <i className="pi pi-search"/>
                                <InputText type="search" onInput={(e: any) => {
                                    setGlobalFilter(e.target.value || []);
                                }}
                                           placeholder={t('customDataTable:search.placeholder')}/>
                                    {props.showGlobalFilterButton &&
                                        <Button icon="pi pi-filter" className="p-button-secondary ml-2" onClick={() => {
                                            setShowGlobalFilterDialog(true);
                                        }}/>}
                                    <Button icon="pi pi-filter-slash" className="p-button-secondary ml-2"
                                            onClick={resetAllFilters}/>
                            </span>

                            </div>}
                        <div className="mt-2 mr-2">
                            <Button icon="pi pi-file-pdf" className="p-button-secondary ml-2" onClick={() => {
                                setShowSearchFileDialog(true);
                            }}/>
                        </div>
                        {props.showExportCSV === true &&
                            <div className="mt-2 mr-2">
                                <Button
                                    className={'p-button-secondary'}
                                    icon={'pi pi-file-export'}
                                    onClick={exportCSV}
                                    tooltip={t('customDataTable:tooltips.exportAsCSV')}
                                    tooltipOptions={{position: 'left'}}
                                />
                            </div>}
                    </div>
                    {props.showLoading &&
                        <div style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            textAlign: 'center',
                            zIndex: '3000'
                        }}>
                            <ProgressSpinner
                                style={{width: '50px', height: '50px', margin: 'auto', zIndex: '4000'}}
                                animationDuration=".5s"
                            />
                        </div>}
                </div>}
            </>
        );

        useEffect(() => {
            setSearchFileContent(undefined);
            if (resultSearchFiles.data) {
                console.log(resultSearchFiles.data);

                let content = [];

                for (let i = 0; i < resultSearchFiles.data.length; i++) {
                    let item = resultSearchFiles.data[i];

                    content.push(<tr>
                        <td className={'border-solid border-1 p-1'}>{item.name}</td>
                        <td className={'border-solid border-1 p-1'}>{item.nachname}</td>
                        <td className={'border-solid border-1 p-1'}>
                            <Button
                                type={'button'}
                                className="p-button-text mx-1 overflow-visible" style={{minWidth: '20px'}}
                                tooltip={props.buttonToolTipDelete ? props.buttonToolTipDelete : t('customDataTable:search.openPdf')}
                                tooltipOptions={{position: 'top'}}
                                onClick={() => {
                                    setSelectedPdf(item);
                                }}>
                                <i className="pi pi-file-pdf"/>
                            </Button>
                            <Button
                                type={'button'}
                                className="p-button-text mx-1 overflow-visible" style={{minWidth: '20px'}}
                                tooltip={props.buttonToolTipDelete ? props.buttonToolTipDelete : t('customDataTable:search.jumpToPerson')}
                                tooltipOptions={{position: 'top'}}
                                onClick={() => {
                                    window.location.href = '/#auditors_' + item.person_id;
                                    window.location.reload();
                                }}>
                                <i className="pi pi-user"/>
                            </Button>
                        </td>
                    </tr>);
                }

                let table = <table className={'mt-2 border-solid border-1'} style={{borderCollapse: 'collapse'}}>
                    <tbody>
                    <tr>
                        <th className={'border-solid border-1 p-1'}>Dateiname</th>
                        <th className={'border-solid border-1 p-1'}>Person</th>
                        <th className={'border-solid border-1 p-1'}>Aktion</th>
                    </tr>
                    {content}
                    </tbody>
                </table>

                setSearchFileContent(table);
            }
        }, [resultSearchFiles.data]);

        const [pdfSearchDelayTimer, setPdfSearchDelayTimer] = useState<any>(undefined);

        useEffect(() => {
            setSearchFileContent(undefined);

            if (pdfSearchDelayTimer) {
                clearTimeout(pdfSearchDelayTimer);
                setPdfSearchDelayTimer(undefined);
            }

            setPdfSearchDelayTimer(setTimeout(() => {
                if (pdfSearchText) {
                    triggerSearchFiles({text: pdfSearchText, and: pdfSearchConnectionAnd}, false);
                    setSearchFileContent(<ProgressSpinner className={'w-full overflow-hidden'}
                                                          style={{height: '50px', margin: '15px auto'}}/>);
                }
            }, 500));
        }, [pdfSearchText, pdfSearchConnectionAnd]);

        const isChanged = (rowData: any, key: string) => {
            // console.log(rowData);
            // return rowData.ChangedByUser || false;
            return rowData.ChangedByUser && rowData.ChangedByUser.indexOf(key) >= 0;
            // let changed = false;
            // if (copy.length) {
            //     let copyItems = copy.filter((x: any) => {
            //         return x.Id === rowData.Id
            //     });
            //
            //     if (copyItems.length) {
            //         changed = copyItems[0][key] !== rowData[key];
            //     }
            // }
            // return changed;
        };

        const textColumn = (rowData: any, key: string, onClick: any) => {
            // console.log('rowData: ',rowData);
            // console.log('key: ', key);
            // console.log(typeof rowData[key]);

            let value = rowData[key];

            if (onClick !== undefined) {
                value = (<div onClick={(e) => {
                    e.stopPropagation();
                    onClick(rowData);
                }}><u>{value}</u></div>);
            }

            if (key === 'is_enabled') {
                const dynamicProps: any = {};
                if (rowData['is_enabled']) dynamicProps['style'] = {color: 'green'};
                return (<div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['is_enabled'] === null ? faSlash : rowData['is_enabled'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        /></div>
                );
            } else if (key === 'is_visible') {
                const dynamicProps: any = {};
                if (rowData['is_visible']) dynamicProps['style'] = {color: 'green'};
                return (<div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['is_visible'] === null ? faSlash : rowData['is_visible'] ? faEye : faEyeSlash}
                            className="m-2"
                            {...dynamicProps}
                        /></div>
                );
            } else if (key === 'is_active') {
                const dynamicProps: any = {};
                if (rowData['is_active']) dynamicProps['style'] = {color: 'green'};
                return (<div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['is_active'] === null ? faSlash : rowData['is_active'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        /></div>
                );
            } else if (key === 'is_logical') {
                const dynamicProps: any = {};
                if (rowData['is_logical']) dynamicProps['style'] = {color: 'green'};
                return (<div className="text-center w-full"><FontAwesomeIcon
                    icon={rowData['is_logical'] === null ? faSlash : rowData['is_logical'] ? faCircleCheck : faCircleXmark}
                    className="m-2 text-center"
                    {...dynamicProps}
                /></div>);
            } else if (key === 'flag') {
                const dynamicProps: any = {};
                if (rowData['flag']) dynamicProps['style'] = {color: 'green'};
                return (
                    <div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['flag'] === null ? faSlash : rowData['flag'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        />
                    </div>
                );
            } else if (key === 'mobile') {
                const dynamicProps: any = {};
                if (rowData['mobile']) dynamicProps['style'] = {color: 'green'};
                return (
                    <div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['mobile'] === null ? faSlash : rowData['mobile'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        />
                    </div>
                );
            } else if (key === 'is_automatic') {
                const dynamicProps: any = {};
                if (rowData['is_automatic']) dynamicProps['style'] = {color: 'green'};
                return (
                    <div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['is_automatic'] === null ? faSlash : rowData['is_automatic'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        />
                    </div>
                );
            } else if (key === 'is_rotational_speed_dependent') {
                const dynamicProps: any = {};
                if (rowData['is_rotational_speed_dependent']) dynamicProps['style'] = {color: 'green'};
                return (
                    <div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['is_rotational_speed_dependent'] === null ? faSlash : rowData['is_rotational_speed_dependent'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        />
                    </div>
                );
            } else if (key === 'save_in_nextcloud') {
                const dynamicProps: any = {};
                if (rowData['save_in_nextcloud']) dynamicProps['style'] = {color: 'green'};
                return (
                    <div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['save_in_nextcloud'] === null ? faSlash : rowData['save_in_nextcloud'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        />
                    </div>
                );
            } else if (key === 'send_as_pdf') {
                const dynamicProps: any = {};
                if (rowData['send_as_pdf']) dynamicProps['style'] = {color: 'green'};
                return (
                    <div className="text-center w-full">
                        <FontAwesomeIcon
                            icon={rowData['send_as_pdf'] === null ? faSlash : rowData['send_as_pdf'] ? faCircleCheck : faCircleXmark}
                            className="m-2"
                            {...dynamicProps}
                        />
                    </div>
                );
            } else {
                return (<div>{value}</div>);
            }
        };

        const checkboxColumn = (rowData: any, key: string) => {
            return (
                <div>
                    <div className={isChanged(rowData, key) ? 'changed' : ''}/>
                    <Checkbox
                        className={(rowData.DeletedByUser ? 'deleted' : '') + (rowData.CreatedByUser ? ' created' : '')}
                        checked={rowData[key]}
                        disabled={true}
                    />
                </div>);
        };

        const dateColumn = (rowData: any, key: string, dateFormat: string = 'DD.MM.YYYY HH:mmZ') => {
            const timezone = getUserFromLocalStorage().timezone;

            let value = '';

            if (rowData[key]) {
                if (timezone) {
                    value = moment(rowData[key]).tz(timezone).format(dateFormat);
                } else {
                    value = moment(rowData[key]).format(dateFormat);
                }
            }

            return (<div>
                <div className={isChanged(rowData, key) ? 'changed' : ''}></div>
                <div className={(rowData.DeletedByUser ? 'deleted' : '') + (rowData.CreatedByUser ? ' created' : '')}>
                    {value !== 'Invalid date' ? value : '-'}
                </div>
            </div>);
        };

        const buttonTemplate = (rowData: any, options: any) => {
            return (<div className="grid grid-nogutter">
                {options.map((item: any, index: number) => {
                    return (
                        <div className={'col-auto' + (index < options.length - 1 ? ' mr-2' : '')}>
                            <Button
                                type={'button'}
                                className="py-1 p-button-sm p-button-outlined"
                                onClick={() => item.onClick(rowData.Id)}>{item.html}
                            </Button>
                        </div>
                    );
                })}
            </div>);
        };

        const buttonColumn = (selection: any) => {
            // console.log(selection);
            const remove = (selection: any) => {
                if (props.onRemoveClick)
                    props.onRemoveClick(selection);
            };
            const edit = (selection: any) => {
                if (props.onEditClick)
                    props.onEditClick(selection);
            };
            let canBeDeleted = true;
            if (selection.users) {
                if (selection.users.length > 0) {
                    canBeDeleted = false;
                }
            }

            // console.log(typeof props.selectionMode === 'undefined')
            // console.log(props.selectionMode === 'single')
            // console.log((typeof props.selectionMode === 'undefined' || props.selectionMode === 'single'))
            // console.log(props.ableToDelete && (typeof props.selectionMode === 'undefined' || props.selectionMode === 'single'))

            return (
                <>

                    {props.ableToUpdate &&
                        <Button
                            type={'button'}
                            className="p-button mx-1 p-button-text overflow-visible" style={{minWidth: '20px'}}
                            tooltip={props.buttonToolTipEdit ? props.buttonToolTipEdit : t('edit')}
                            tooltipOptions={{position: 'top'}} onClick={() => {
                            edit(selection);
                        }}>
                            <i className={'pi pi-pencil'}/>
                        </Button>}
                    {props.ableToDelete && (typeof props.selectionMode === 'undefined' || props.selectionMode === 'single') &&
                        <Button
                            type={'button'}
                            className="p-button-danger p-button-text mx-1 overflow-visible" style={{minWidth: '20px'}}
                            disabled={!canBeDeleted}
                            tooltip={props.buttonToolTipDelete ? props.buttonToolTipDelete : t('delete')}
                            tooltipOptions={{position: 'top'}}
                            onClick={() => {
                                remove(selection);
                            }}>
                            <i className="pi pi-trash"/>
                        </Button>}
                    {props.customButtons && props.customButtons.map(item => {
                        if (item.permitted) {
                            // Setze icon initial mit dem nullten Element (Standardfall)
                            let icon = item.icons[0].icon;
                            // Bei mehreren, setze icon je nach Wert
                            for (const iconObj of item.icons) {
                                const value = selection[iconObj.key];
                                if (value === iconObj.value) {
                                    icon = iconObj.icon;
                                    break;
                                }
                            }

                            const className = item.className ? item.className + ' p-button mx-1 p-button-text' : 'p-button mx-1 p-button-text';

                            return (
                                <Button
                                    type={'button'}
                                    className={className} style={{minWidth: '20px'}}
                                    tooltip={item.tooltip} tooltipOptions={item.tooltipOptions}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        item.onClick(selection);
                                    }}
                                >
                                    {icon}
                                </Button>
                            );
                        }
                    })}
                </>
            );
        };

        const imageTemplate = (rowData: any, key: any) => {
            return <img src={rowData[key]}/>;
        };

        const skeletonBodyTemplate = () => {
            return <Skeleton height="2.3rem"></Skeleton>;
        };

        const onPage = (e: any) => {
            const rows = parseInt(e.rows);
            localStorage.setItem('pageRows_' + props.id, e.rows);
            setPageRows(rows);
            setFirst(parseInt(e.first));
            return rows;
        };

        const template1: any =
            {
                layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown',
                'PrevPageLink':
                    (options: any) => {
                        return (
                            <button
                                type="button" className={options.className} onClick={options.onClick}
                                disabled={options.disabled}>
                                <span className="p-3"><i className="pi pi-angle-left"></i></span>
                                <Ripple/>
                            </button>
                        );
                    },
                'NextPageLink':
                    (options: any) => {
                        return (
                            <button
                                type="button" className={options.className} onClick={options.onClick}
                                disabled={options.disabled}>
                                <span className="p-3"><i className="pi pi-angle-right"></i></span>
                                <Ripple/>
                            </button>
                        );
                    },
                'PageLinks':
                    (options: any) => {
                        // console.log(options)
                        // if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                        //     const className = classNames(options.className, {'p-disabled': true});
                        //
                        //     return <span className={className} style={{userSelect: 'none'}}>...</span>;
                        // }

                        if (options.page != options.currentPage) {
                            return <div></div>;
                        }

                        return (
                            <button type="button" className={options.className} onClick={options.onClick}>
                                {options.page + 1 + '/' + (options.totalPages)}
                                <Ripple/>
                            </button>
                        );
                    },
                'RowsPerPageDropdown':
                    (options: any) => {
                        const dropdownOptions = [
                            {label: 3, value: 3},
                            {label: 5, value: 5},
                            {label: 10, value: 10},
                            {label: 20, value: 20},
                            {label: 50, value: 50},
                            // {label: t('customDataTable:paginator.all'), value: options.totalRecords}
                        ];

                        if (!props.paginatorHideAll) {
                            dropdownOptions.push({label: t('customDataTable:paginator.all'), value: options.totalRecords});
                        }

                        return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange}/>;
                    },
            };

        const booleanTemplate = (rowData: any, field: string) => {
            return (
                <div>
                    <i className={classNames('pi', {
                        'true-icon pi-check-circle': rowData[field],
                        'false-icon pi-minus': !rowData[field]
                    })}
                    />
                </div>);
        };

        const statusLightsTemplate = (rowData: any, field: string, options: any) => {
            return getStatusLight(options.rowIndex, rowData[field], rowData['active_id']);
        };

        const statusFilterTemplate = (options: any) => {
            let statuses: any[] = [1, 2, 3];

            return <Dropdown value={options.value} options={statuses}
                             onChange={(e) => options.filterApplyCallback(e.value, options.index)}
                             itemTemplate={(e: any) => {
                                 return getStatusLight(0, e, 0);
                             }} valueTemplate={(e: any) => {
                return getStatusLight(0, e, 0);
            }} className="p-column-filter"/>;
        };

        const getStatusLight = (index: number, field: number, active_id: number) => {
            return (<div><StatusLight id={'statuslight-' + index} value={field}
                                      info={'Testinfo für Row ' + index}
                                      light={false}
                                      horizontal={true}
                                      inactive={active_id != null ? active_id > 0 : false}
                                      scale={isMobile ? 0.8 : undefined}/></div>);
        };

        const filterDropdownTemplate = (options: any, values?: any[], field?: string) => {
            // console.log("options", options);
            // console.log("values", values);
            // console.log('colfilter', columnFilters);

            if (values) {
                for (let i = 0; i < values.length; i++) {
                    const item = values[i];

                    if (item.value === undefined) {
                        item.value = item.id;
                    }
                }
            }

            // console.log("values", values);

            return (
                <Dropdown
                    value={options.value} options={values}
                    itemTemplate={(option: any) => {
                        return (<div>{option.name}</div>);
                    }}
                    // selectedItemsLabel={'{0} ' + t('customDataTable:filter.items_selected')}
                    // maxSelectedLabels={1}
                    onChange={(e) => options.filterApplyCallback(e.value, options.index)}
                    optionLabel={field ? field : 'name'}
                    placeholder={t('customDataTable:filter.any')}
                    className="p-column-filter"
                />
            );
        };

        const allowExpansion = (rowData: any) => {
            return rowData.expandableElement !== undefined;
        };

        const rowExpansionTemplate = (rowData: any) => {
            return (<div className="relative" style={{'zIndex': '0'}}>{rowData.expandableElement}</div>);
        };

        const textEditor = (options: any) => {
            return getEditor(options, 'text');
        };

        const numberEditor = (options: any) => {
            return getEditor(options, 'number');
        };

        const dateEditor = (options: any) => {
            return getEditor(options, 'datetime-local');
        };

        const getEditor = (options: any, type: string) => {
            return (
                <InputText
                    className={classNames(options.column.props.editCellClassName)}
                    style={options.column.props.editCellStyle}
                    type={type} value={options.value}
                    onChange={(e) => options.editorCallback(e.target.value)}
                />
            );
        };

        const dropdownEditor = (options: any, items: any) => {
            return (
                <Dropdown
                    className={classNames('w-full', options.column.props.editCellClassName)}
                    style={options.column.props.editCellStyle}
                    value={options.value ? options.value.toString() : null}
                    options={items.map((i: any) => {
                        return {value: i.id, label: i.name};
                    })}
                    onChange={(e) => options.column.props.editCell(e.target.value)}
                />
            );
        };

        const settings = getUserFromLocalStorage().settings;

        useEffect(() => {
            if (useUserFilters) {
                let filterSettings: any = {};

                if (params && params.projectId) {
                    if (settings[params.projectId]) {
                        filterSettings = settings[params.projectId]['filters_' + props.id];
                    }
                }

                if (filterSettings && props.filters) {
                    // console.log(filterSettings)
                    for (let i = 0; i < Object.keys(filterSettings).length; i++) {
                        const key = Object.keys(filterSettings)[i];
                        props.filters[key] = filterSettings[key];
                    }
                }
            }
        }, [useUserFilters]);

        const spreadProps = {...props};

        // @ts-ignore
        delete spreadProps.editable;
        delete spreadProps.ableToDelete;
        delete spreadProps.ableToUpdate;
        delete spreadProps.headerTitle;
        delete spreadProps.onEditClick;
        delete spreadProps.onRemoveClick;
        delete spreadProps.hideGlobalFilter;
        // @ts-ignore
        delete spreadProps.sortable;
        delete spreadProps.expandable;
        delete spreadProps.filters;
        delete spreadProps.dataloaded;
        delete spreadProps.skeletonCount;
        delete spreadProps.displayColumnFilter;
        delete spreadProps.paginatorHideAll;
        delete spreadProps.showGlobalFilterButton;

        let filterDelayTimer: any;

        const onSort = (e: any) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
            setUserSettings('sort_' + props.id, {sortField: e.sortField, sortOrder: e.sortOrder});
        };

        return (<>
                <DataTable
                    {...spreadProps}
                    key={childKey}
                    ref={tableRef}
                    onValueChange={setChangedValue}
                    value={showSkeleton ? skeletonValues : props.value}
                    reorderableColumns={true}
                    onColReorder={(e: any) => {
                        //  console.log('reorder', e);
                        const columnOrder = e.columns.map((x: any) => x.props.field);
                        setUserSettings('columnorder_' + props.id, columnOrder);
                    }}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={onSort}
                    rowClassName={(data: any, options: any) => {
                        if (props.rowClass && props.rowClass.length > 0) {
                            for (let i = 0; i < props.rowClass.length; i++) {
                                const rc = props.rowClass[i];
                                if (data[rc.key] === rc.value) {
                                    return rc.class;
                                }
                            }
                        }
                        return '';
                    }}
                    scrollHeight={props.scrollHeight || 'flex'}
                    style={props.style || {'tableLayout': 'fixed'}}
                    columnResizeMode="fit"
                    resizableColumns={!isMobile}
                    filters={filters}
                    showGridlines
                    className={classNames('pt-2', (props.className ? ' ' + props.className : ''), isMobile ? 'stack' : '')}
                    globalFilter={globalFilter}
                    header={header}
                    scrollable={!isMobile}
                    selectionMode={(typeof props.selectionMode === 'undefined') ? 'single' : props.selectionMode}
                    // onSelectionChange={(e: any) => {
                    //     console.log(e);
                    //     if (props.onSelectionChange) {
                    //         props.onSelectionChange(e);
                    //     }
                    // }}
                    paginator={showPaginator()}
                    onPage={onPage}
                    first={first ? first : 0}
                    rows={props.scrollToFirstExpaned ? props.value.length : pageRows ? pageRows : 10}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    responsiveLayout="stack"
                    breakpoint={breakpoint + 'px'}
                    paginatorTemplate={template1}
                    currentPageReportTemplate={t('customDataTable:paginator.showing') + ' {first} ' + t('customDataTable:paginator.to') + ' {last} ' + t('customDataTable:paginator.of') + ' {totalRecords}'}
                    emptyMessage={t('customDataTable:noentry')}
                    filterDisplay="menu"
                    onFilter={(e: any) => {
                        if (props.onFilterChanged) {
                            props.onFilterChanged(e.filters);
                        } else {
                            setFilters(e.filters);
                        }

                        if (useUserFilters) {
                            let save = true;
                            delete e.filters.global;

                            if (filterTemp) {
                                const tmp = filterTemp;

                                delete tmp.global;

                                if (JSON.stringify(e.filters) === JSON.stringify(tmp)) {
                                    save = false;
                                }
                            }

                            if (save) {
                                clearTimeout(filterDelayTimer);
                                filterDelayTimer = setTimeout(() => {
                                    if (params && params.projectId) {
                                        setUserSettings('filters_' + props.id, e.filters, params.projectId);
                                    }
                                }, 1500);
                            }

                            setFilterTemp(e.filters);
                        }

                        setPreventGlobalChange(true);
                        setGlobalFilterDialogValues((prevState: any) => {
                            let temp = [...prevState];

                            for (let i = 0; i < temp.length; i++) {
                                temp[i].value = undefined;
                            }

                            return temp;
                        });
                    }}
                    expandedRows={expandedRows} onRowToggle={(e) => {
                    setExpandedRows(e.data);
                    if (props.onRowToggle)
                        props.onRowToggle(e);
                }}
                    onRowExpand={() => {
                        props.onRowExpand;
                        setTimeout(onScroll, 100);
                    }}
                    onRowCollapse={() => {
                        props.onRowCollapse;
                        setTimeout(onScroll, 100);
                    }}
                    rowExpansionTemplate={rowExpansionTemplate}
                    // onColumnResizeEnd={(e) => {
                    //     setUserSettings('dt_column_width_' + props.id + '_' + e.column.props.field, e.element.offsetWidth);
                    // }}
                >
                    {props.columns && selectedColumns.length > 0 && props.editable && !isMobile &&
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : buttonColumn}
                            columnKey={'buttons'}
                            className={'no-hover'}
                            field="buttons"
                            style={{minWidth: buttonColumnWidth + 'px', maxWidth: buttonColumnWidth + 'px'}}
                            // frozen={true}
                            alignFrozen="left"
                            reorderable={false}
                            resizeable={false}
                        />}
                    {props.columns && selectedColumns.length > 0 && (props.selectionMode && props.selectionMode === 'multiple') && !isMobile &&
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            columnKey={'multiple'}
                            className="selection-column no-hover" selectionMode={props.selectionMode}
                            reorderable={false}
                            resizeable={false}
                            // frozen={true}
                        />}
                    {props.columns && selectedColumns.length > 0 && (props.selectionMode && props.selectionMode === 'checkbox') && !isMobile &&
                        // In diesem Modus kann man eine Zeile nur selektieren, wenn die Checkbox angeklickt wurde. Dann muss die
                        // Spalte aber den selectionMode 'multiple' besitzen und die DataTable den selectionMode 'checkbox'
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            columnKey={'checkbox'}
                            className="selection-column no-hover" selectionMode={'multiple'}
                            reorderable={false}
                            resizeable={false}
                            // frozen={true}
                        />}
                    {props.columns && selectedColumns.length > 0 && props.expandable && !isMobile &&
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            columnKey={'expander'}
                            className="expander-column no-hover" expander={allowExpansion}
                            reorderable={false}
                            resizeable={false}
                            // frozen={true}
                        />}
                    {props.columns && selectedColumns.length > 0 && props.columns && selectedColumns.map((item: ColumnObject) => {
                        item = {...item};
                        if (isMobile) {
                            delete item.style;
                        }

                        const filterTemplate = {
                            showApplyButton: false,
                            showClearButton: true,
                            showAddButton: true,
                            showFilterMatchModes: item.filterDropdownValues ? false : item.showFilterMatchModes,
                            showFilterMenuOptions: true,
                            showFilterOperator: true,
                            maxConstraints: 10,
                            filterHeader: <div className='w-full flex justify-content-end'><Tooltip target='.filter-info'
                                                                                                    position='bottom'>
                                <div>
                                    <div className='mb-1'>Beginnt mit: Beispiel: Max Mustermann / Der Name beginnt mit Max
                                        (Maria wird nicht angezeigt)
                                    </div>
                                    <hr/>
                                    <div className='mb-1'>Enthält: nur / Beispiel: Mustermann</div>
                                    <hr/>
                                    <div className='mb-1'>Enthält nicht: Ausschluss / Beispiel Mustermann wird
                                        ausgeschlossen
                                    </div>
                                    <hr/>
                                    <div className='mb-1'>Endet mit: nur / Beispiel Mustermann / Mustermann-Müller wird
                                        nicht angezeigt
                                    </div>
                                    <hr/>
                                    <div className='mb-1'>Ist gleich: Kompletter Name / Beispiel Max Maria Mustermann
                                    </div>
                                    <hr/>
                                    <div className='mb-1'>Ist ungleich: kompletter Name nicht sichtbar / Beispiel: Max
                                        Mustermann nicht sichtbar
                                    </div>

                                </div>
                            </Tooltip> <i className='m-2 filter-info pi pi-info-circle'/></div>
                        };


                        // Funktioniert leider nicht, scheinbar gibt es keine Möglichkeit die Columns resizeable zu machen und dann initial eine individuelle Breite zu setzen...
                        // minWidth würde die Breite setzen, aber dann hat man keine Möglichkeit die Column wieder kleiner zu bekommen.
                        // if (getUserFromLocalStorage().settings) {
                        //     let width = getUserFromLocalStorage().settings['dt_column_width_' + props.id + '_' + item.field];
                        //
                        //     if (item.style === undefined) {
                        //         item.style = {}
                        //     }
                        //
                        //     if (width && item.style.width === undefined) {
                        //         item.style.width = width + 'px';
                        //     }
                        // }

                        switch (item.type) {
                            case ColumnObjectType.Number:
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        columnKey={item.field}
                                        key={item.field} sortable={props.sortable}
                                        body={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : (rowData: any) => textColumn(rowData, item.field, item.onClick)}
                                        editor={item.editCell ? (options) => numberEditor(options) : null}
                                        onCellEditComplete={item.editCell ? (e: any) => {
                                            if (item.editCell) {
                                                item.editCell(e.newValue);
                                            }
                                        } : undefined}
                                    />
                                );

                            case ColumnObjectType.Checkbox:
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={showSkeleton ? skeletonBodyTemplate : undefined}
                                        columnKey={item.field}
                                        key={item.field}
                                        selectionMode="multiple" // sortable={props.sortable}
                                        style={{minWidth: '10px', maxWidth: '30px'}}
                                    />
                                );

                            case ColumnObjectType.Dropdown:
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : (rowData: any) => textColumn(rowData, item.field, item.onClick)}
                                        key={item.field} columnKey={item.field} sortable={props.sortable}
                                        editor={item.editCell ? (options) => dropdownEditor(options, item.filterDropdownValues) : null}
                                    />
                                );

                            case ColumnObjectType.Date:
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={(rowData) => showSkeleton ? skeletonBodyTemplate : item.body ? item.body(rowData) : dateColumn(rowData, item.field, item.dateFormat)}
                                        key={item.field} columnKey={item.field} sortable={props.sortable}
                                        editor={item.editCell ? (options) => dateEditor(options) : null}
                                        onCellEditComplete={item.editCell ? (e: any) => {
                                            if (item.editCell) {
                                                item.editCell(e.newValue);
                                            }
                                        } : undefined}
                                    />
                                );

                            case ColumnObjectType.Buttons:
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={(rowData) => showSkeleton ? skeletonBodyTemplate : buttonTemplate(rowData, item.options)}
                                        key={item.field} columnKey={item.field} sortable={props.sortable}
                                    />
                                );

                            case ColumnObjectType.Image:
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={(rowData) => showSkeleton ? skeletonBodyTemplate : imageTemplate(rowData, item.field)}
                                        key={item.field} columnKey={item.field} sortable={props.sortable}
                                    />
                                );

                            case ColumnObjectType.Boolean:
                                filterTemplate.showFilterOperator = false;
                                filterTemplate.showFilterMatchModes = false;
                                filterTemplate.showAddButton = false;
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={(rowData) => showSkeleton ? skeletonBodyTemplate : booleanTemplate(rowData, item.field)}
                                        key={item.field} columnKey={item.field} sortable={props.sortable}
                                        // style={{minWidth: '6rem'}}
                                    />
                                );

                            case ColumnObjectType.StatusLights:
                                filterTemplate.showFilterMatchModes = false;
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={(rowData, options) => showSkeleton ? skeletonBodyTemplate : statusLightsTemplate(rowData, item.field, options)}
                                        key={item.field} sortable={props.sortable}
                                        filterElement={statusFilterTemplate}
                                        // style={{minWidth: '6rem'}}
                                    />
                                );

                            default: // Text
                                return (
                                    <Column
                                        {...item}
                                        {...filterTemplate}
                                        body={showSkeleton ? skeletonBodyTemplate : item.body ? item.body : (rowData: any) => textColumn(rowData, item.field, item.onClick)}
                                        key={item.field} columnKey={item.field} sortable={props.sortable}
                                        onFilterClear={() => {
                                            setColumnFilters((prevState: any) => ({
                                                ...prevState,
                                                [item.field]: null
                                            }));
                                        }}
                                        style={item.style ? {
                                            width: item.style.width,
                                            flex: `1 ${item.style.width} 1 !important`
                                        } : undefined}
                                        filterElement={item.filterDropdownValues ? (options) => {
                                            // console.log('options: ', options)
                                            // console.log('filterDropdownValues: ', item.filterDropdownValues)
                                            // console.log('filterDropdownField: ', item.filterDropdownField)
                                            return filterDropdownTemplate(options, item.filterDropdownValues, item.filterDropdownField);
                                        } : item.filterElement ? item.filterElement : null}

                                        editor={item.editCell ? (options) => textEditor(options) : null}
                                        onCellEditComplete={item.editCell ? (e: any) => {
                                            if (item.editCell) {
                                                item.editCell(e.newRowData);
                                            }
                                        } : undefined}
                                    />
                                );
                        }

                    })}
                    {props.columns && selectedColumns.length > 0 && props.expandable && isMobile &&
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            columnKey={'expander'}
                            className="expander-column pt-2 no-hover" expander={allowExpansion}
                            style={{float: 'right', minWidth: '30px', maxWidth: '30px'}}
                            reorderable={false}
                            resizeable={false}
                            // frozen={true}
                        />}
                    {props.columns && selectedColumns.length > 0 && (props.selectionMode && props.selectionMode === 'multiple') && isMobile &&
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            columnKey={'multiple'}
                            className="selection-column no-hover" selectionMode={props.selectionMode}
                            style={{float: 'left'}}
                            reorderable={false}
                            resizeable={false}
                            // frozen={true}
                        />}
                    {props.columns && selectedColumns.length > 0 && (props.selectionMode && props.selectionMode === 'checkbox') && isMobile &&
                        // In diesem Modus kann man eine Zeile nur selektieren, wenn die Checkbox angeklickt wurde. Dann muss die
                        // Spalte aber den selectionMode 'multiple' besitzen und die DataTable den selectionMode 'checkbox'
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : undefined}
                            columnKey={'checkbox'}
                            className="selection-column no-hover" selectionMode={'multiple'}
                            style={{float: 'left'}}
                            reorderable={false}
                            resizeable={false}
                            // frozen={true}
                        />}
                    {props.columns && selectedColumns.length > 0 && props.editable && isMobile &&
                        <Column
                            body={showSkeleton ? skeletonBodyTemplate : buttonColumn}
                            columnKey={'buttons'}
                            className={'no-hover'}
                            field="buttons"
                            style={{minWidth: buttonColumnWidth + 'px', maxWidth: buttonColumnWidth + 'px', float: 'left'}}
                            // frozen={true}
                            alignFrozen="left"
                            reorderable={false}
                            resizeable={false}
                        />}
                    {isMobile && <Column/>}
                </DataTable>
                {props.showGlobalFilterButton &&
                    <CustomDialog
                        style={{maxWidth: '800px'}}
                        onCancel={() => {
                            setShowGlobalFilterDialog(false);
                        }} visible={showGlobalFilterDialog} header={t('customDataTable:filter.name')}>
                        <div className={'grid'}>
                            {props.columns && props.columns.map((item: ColumnObject, index: number) => {
                                return (
                                    <div key={'dialog_' + index} className={'col-4'}>
                                        <Input edit={true} name={item.field} label={item.header}
                                               value={globalFilterDialogValues.length ? globalFilterDialogValues[index].value : undefined}
                                               onChange={(e: any) => {
                                                   setGlobalFilterDialogValues((prevState: any) => {
                                                       const temp = [...prevState];

                                                       temp[index].value = e.target.value;

                                                       return temp;
                                                   });
                                               }}/></div>);

                            })}
                        </div>
                        <Button icon={'pi pi-filter-slash'} className={'mt-2'} onClick={resetAllFilters}/>
                    </CustomDialog>}
                <CustomDialog onCancel={() => {
                    setShowSearchFileDialog(false);
                    setSearchFileContent(undefined);
                    setPdfSearchText('');
                    triggerSearchFiles.data = undefined;
                }} visible={showSearchFileDialog} header={t('customDataTable:search.pdf')}>
                    <>
                        <Input edit={true} name={'pdf-search-connection'} type={'checkbox'}
                               label={t('customDataTable:search.andConnection')}
                               noMargin={true}
                               value={pdfSearchConnectionAnd} onChange={(e) => {
                            setPdfSearchConnectionAnd(e.target.value)
                        }}></Input>
                        <p>{pdfSearchConnectionAnd ? t('customDataTable:search.pdfSearchInfoAnd') : t('customDataTable:search.pdfSearchInfoOr')}</p>
                        <Input edit={true} name={'search-pdf-text'}
                               placeholder={t('customDataTable:search.placeholder')}
                               value={pdfSearchText}
                               onChange={(e: any) => {
                                   console.log(e);
                                   setPdfSearchText(e.target.value);
                               }}
                        />
                        {searchFileContent}
                    </>
                </CustomDialog>
                {selectedPdf &&
                    <CustomDialog maximized={true} onCancel={() => {
                        setSelectedPdf(undefined)
                    }} visible={selectedPdf} header={selectedPdf.name}>
                        <object className={'w-full h-full'} data={'data:application/pdf;base64, ' + selectedPdf.data}
                                type="application/pdf">
                            <div>No PDF viewer available</div>
                        </object>
                    </CustomDialog>}
            </>
        );
    }
;

export default CustomDataTable;
