import CustomDataTable, {ColumnObject} from '../global/CustomDataTable';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {deleteRole, getPermissionTypes, getRoles, Role} from '../functions/UserManagement';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Chip} from 'primereact/chip';
import keycloakfetch from "../../functions/keycloakfetch";
import settings from "../../config/settings";
import {getColumnFields, getFilterFields, Person} from "../functions/Persons";
import {Card} from "primereact/card";
import {PersonDialogEditForm} from "./PersonDialogEditForm";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {Button} from "primereact/button";
//import store from "../../redux/store";
import {
    useToggleActiveStatePersonMutation,
    useGetAnredeQuery,
    useGetBearbeiterQuery,
    useGetEinsatzbereichQuery,
    useGetIafCodesQuery,
    useGetIsoScopesQuery,
    useGetKompetenzQuery,
    useGetKundeQuery,
    useGetLanguageQuery, useGetPersonKompetenzQuery,
    useGetPersonQuery, useGetProfitcenterQuery,
    useGetStatusbearbeitungQuery, useGetTitleQuery,
    useGetWettbewerberQuery, useGetKritikalitaetQuery
} from "../../redux/rtk/injectedPersonApi";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";
import {confirmDialog} from "primereact/confirmdialog";
import {getUserFromLocalStorage} from "../functions/Global";
import moment from "moment";
import CustomDialog from "../global/CustomDialog";
import Input from "../global/Input";

type Nullable<T> = T | null;

type Props = {}
export const PersonsOverview = (props: Props) => {
    const {t} = useTranslation(['common']);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [persons, setPersons] = useState<Person[]>([]);
    const [selectedValue, setSelectedValue] = useState<Person | undefined>(undefined);
    const [showValue, setShowValue] = useState<boolean>(false);
    const [showCreatePerson, setShowCreatePerson] = useState<boolean>(false);
    const [columns, setColumns] = useState<ColumnObject[]>([]);
    const [resubmissionContent, setResubmissionContent] = useState<any>(null);

    const {data: personQuery = undefined} = useGetPersonQuery({});
    // const personKompetenzQuery = useGetPersonKompetenzQuery({});

    const anredeQuery = useGetAnredeQuery({});
    const titelQuery = useGetTitleQuery({});
    const bearbeiterQuery = useGetBearbeiterQuery({});
    const kompetenzQuery = useGetKompetenzQuery({});
    const kundeQuery = useGetKundeQuery({});
    // const languageQuery = useGetLanguageQuery({});
    const statusbearbeitungQuery = useGetStatusbearbeitungQuery({});
    const isoScopesQuery = useGetIsoScopesQuery({});
    const iafCodesQuery = useGetIafCodesQuery({});
    const profitcenterQuery = useGetProfitcenterQuery({});
    const kritikalitaetQuery = useGetKritikalitaetQuery({});


    // useEffect(() => {
    //     keycloakfetch.get(settings.apiUrl + '/getPerson').then(result => {
    //         if (result) {
    //             setPersons(result);
    //         }
    //         setDataLoaded(true);
    //     });
    // }, []);

    useEffect(() => {
        if (anredeQuery.data &&
            bearbeiterQuery.data &&
            kompetenzQuery.data &&
            kundeQuery.data &&
            // languageQuery.data &&
            statusbearbeitungQuery.data &&
            isoScopesQuery.data &&
            iafCodesQuery.data &&
            profitcenterQuery.data &&
            titelQuery.data &&
            kritikalitaetQuery.data &&
            personQuery) {

            let types = {
                anrede: anredeQuery.data,
                bearbeiter: bearbeiterQuery.data,
                kompetenz: kompetenzQuery.data,
                // sprache: languageQuery.data,
                statusBearbeitung: statusbearbeitungQuery.data,
                isoScopes: isoScopesQuery.data,
                iafCodes: iafCodesQuery.data,
                profitcenter: profitcenterQuery.data,
                titel: titelQuery.data,
                kritikalitaet: kritikalitaetQuery.data,
            }

            setColumns(getColumnFields(t, types));

            setPersons(personQuery.map((item: any) => {
                return {
                    ...item, kompetenzenFilter: item['kompetenzen'] ? {
                        label: item['kompetenzen'].map((item: any) => {
                            return `${item.isocode}, ${getTypeName('kompetenz', item.kompetenz_id, types)}, (${item.scopes ? item.scopes.map((scope: any) => {
                                return getTypeName('iafCodes', scope, types, 'iaf_code');
                            }).join(' / ') : ''})`;
                        },).join('], ['),
                        values: item['kompetenzen'].map((komp: any) => {
                            return {isoCode: komp.iso_code_id, scopes: komp.scopes}
                        })
                    } : ''
                };
            }));
            setDataLoaded(true);
        }
    }, [anredeQuery,
        bearbeiterQuery,
        kompetenzQuery,
        kundeQuery,
        // languageQuery,
        statusbearbeitungQuery,
        isoScopesQuery,
        iafCodesQuery,
        profitcenterQuery,
        titelQuery,
        JSON.stringify(personQuery)])

    const getTypeName = (key: string, value: string, types: any, returnKey: string = 'name') => {
        let result = undefined;
        if (types[key] && value) {
            result = types[key].find((item: any) => {
                return item.id === value;
            });

            if (result) {
                result = result[returnKey];
            }
        }
        return result;
    }

    // useEffect(() => {
    //     if (personQuery) {
    //         console.log('personquery', personQuery)
    //         setPersons(personQuery.map((item: any) => {
    //             return;
    //         }));
    //         setDataLoaded(true);
    //     }
    // }, [JSON.stringify(personQuery)]);

    useEffect(() => {
        if (window.location.hash.indexOf('_') > -1 && persons) {
            const hashElements = window.location.hash.split('_');
            if (hashElements.length > 1) {
                const id = hashElements[1];
                const sPerson = persons.find((item: Person) => {
                    return item.id === id;
                });
                if (sPerson) {
                    setSelectedValue(sPerson);
                    setShowValue(true);
                }
            }
        }
    }, [persons]);

    useEffect(() => {
        setResubmissionContent(null);
        if (persons) {
            let content = [];

            let resubmissions: Person[] = persons.filter((person: Person) => {
                return person.wiedervorlage_aktiv && person.wiedervorlage_datum && new Date(person.wiedervorlage_datum) < new Date();
            });

            for (let i = 0; i < resubmissions.length; i++) {
                let item = resubmissions[i];

                content.push(<tr>
                    <td className={'border-solid border-1 p-1'}>{moment(item.wiedervorlage_datum).format(t('dateformatWithoutTime'))}</td>
                    <td className={'border-solid border-1 p-1'}>{item.vorname + ' ' + item.name}</td>
                    <td className={'border-solid border-1 p-1'}>
                        <Button
                            type={'button'}
                            className="p-button-text mx-1 overflow-visible" style={{minWidth: '20px'}}
                            tooltip={t('customDataTable:search.jumpToPerson')}
                            tooltipOptions={{position: 'top'}}
                            onClick={() => {
                                window.location.href = '/#auditors_' + item.id;
                                window.location.reload();
                            }}>
                            <i className="pi pi-user"/>
                        </Button>
                    </td>
                </tr>);
            }

            if (content.length) {
                let table = <table className={'mt-2 border-solid border-1'} style={{borderCollapse: 'collapse'}}>
                    <tbody>
                    <tr>
                        <th className={'border-solid border-1 p-1'}>Wiedervorlage</th>
                        <th className={'border-solid border-1 p-1'}>Person</th>
                        <th className={'border-solid border-1 p-1'}>Aktion</th>
                    </tr>
                    {content}
                    </tbody>
                </table>

                setResubmissionContent(table);
            }
        }
    }, [persons]);

    useEffect(() => {
        console.log(resubmissionContent)
    }, [resubmissionContent]);

    return <div className={'h-full'}>
        <div className={'scroll-card-main-content md:absolute overflow-auto card'}>
            <CustomDataTable
                id='persons'
                columns={columns}
                header={
                    <div className={'grid mr-3'}>
                        <div className={'col'}>
                            <h4 className={'m-0'}>{t('persons:name')}</h4>
                        </div>
                        <div className={'col flex justify-content-end flex-wrap mb-auto'}>
                            <Button type={'button'}
                                    className={'p-button-success p-button-icon-only p-button-outlined'}
                                    icon={'pi pi-plus'}
                                    disabled={!checkPermission(permissions.isAdmin)}
                                    onClick={() => {
                                        setShowCreatePerson(true);
                                    }}/>
                            {/*<div className={'inline mx-2'}>*/}
                            {/*    <Menu model={getButtons()} popup ref={menu} id="popup_menu"/>*/}
                            {/*    <Button className={'p-button-icon-only p-button-outlined'}*/}
                            {/*            icon="pi pi-angle-down"*/}
                            {/*            type="button"*/}
                            {/*            onClick={(event) => {*/}
                            {/*                if (menu.current) {*/}
                            {/*                    menu.current.toggle(event);*/}
                            {/*                }*/}
                            {/*            }} aria-controls="popup_menu" aria-haspopup/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                }
                showExportCSV={true}
                editable={true}
                sortable={true}
                filters={getFilterFields}
                value={persons}
                expandable={false}
                /*   onRemoveClick={(person: any) => {
                       if (person.is_deleted) {
                           confirmDialog({
                               header: t('persons:dialogs.restorePersonHeader'),
                               message: t('persons:dialogs.restorePersonMessage'),
                               icon: 'pi pi-exclamation-triangle',
                               acceptLabel: t('yes'),
                               rejectLabel: t('no'),
                               accept: () => {
                                   toggleActiveStatePerson({
                                       person_id: person.id,
                                       is_deleted: false
                                   }).then((result: any) => {
                                       if (!result.error) {
                                           showMessageOnSuccess('Erfolg', 'Auditor erfolgreich wiederhergestellt');
                                       }
                                   });
                               }
                           })
                       } else {
                           confirmDialog({
                               header: t('persons:dialogs.deletePersonHeader'),
                               message: t('persons:dialogs.deletePersonMessage'),
                               icon: 'pi pi-exclamation-triangle',
                               acceptLabel: t('yes'),
                               rejectLabel: t('no'),
                               accept: () => {
                                   toggleActiveStatePerson({
                                       person_id: person.id,
                                       is_deleted: true
                                   }).then((result: any) => {
                                       if (!result.error) {
                                           showMessageOnSuccess('Erfolg', 'Auditor erfolgreich gelöscht');
                                       }
                                   });
                               }
                           });
                       }
                   }}*/
                onEditClick={(e: any) => {
                    setSelectedValue(e);
                    setShowValue(true);
                }}
                ableToUpdate={true}
                // ableToDelete={checkPermission(permissions.isAdmin)}
                dataloaded={dataLoaded}
                displayColumnFilter={true}
                hideGlobalFilter={false}
                paginator={true}
                showGlobalFilterButton={true}
            /></div>
        {showValue && <PersonDialogEditForm
            selected={selectedValue}
            setSelected={setSelectedValue}
            show={showValue}
            setShow={setShowValue}
            onFinished={(data: Person) => {
                setPersons((prevState: Person[]) => {
                    let temp = [...prevState];
                    for (let i = 0; i < temp.length; i++) {
                        if (temp[i].id === data.id) {
                            temp[i] = {...data};
                        }
                    }
                    return temp;
                });
            }}
        />}
        {showCreatePerson && <PersonDialogEditForm
            show={showCreatePerson}
            setShow={setShowCreatePerson}
            onFinished={(data: Person) => {
                setPersons((prevState: Person[]) => {
                    let temp = [...prevState];
                    temp.push(data);
                    return temp;
                });
            }}
        />}
        {resubmissionContent && <CustomDialog onCancel={() => {
            setResubmissionContent(null);
        }} visible={resubmissionContent} header={t('persons:columns.wiedervorlagen')}>
            {resubmissionContent}
        </CustomDialog>}
    </div>;
};
