import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Input from '../global/Input';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Button} from 'primereact/button';
import FormPrompt from '../global/FormPrompt';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBook,
    faCoins,
    faDatabase, faEye, faHandshake,
    faList,
    faMapLocation, faMoneyBill, faPerson,
    faPlus,
    faProjectDiagram,
    faTrafficLight, faUser
} from '@fortawesome/free-solid-svg-icons';
import {Person} from '../functions/Persons';
import {deleteLocation, getUserFromLocalStorage, Nullable} from '../functions/Global';
import EditDialogLayout from '../global/EditDialogLayout';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import {
    useGetAnredeQuery,
    useGetBearbeiterQuery,
    useGetEinsatzbereichQuery,
    useGetKundeQuery,
    useGetLanguageQuery, useGetProfitcenterQuery,
    useGetStatusbearbeitungQuery,
    useGetTitleQuery,
    useGetKritikalitaetQuery, useLazyGetDoublettenQuery, useToggleActiveStatePersonMutation, useDeletePersonMutation
} from '../../redux/rtk/injectedPersonApi';
import FileManagement from '../global/FileManagement';
import StatusLight from '../global/StatusLight';
import {TabPanel, TabView} from 'primereact/tabview';
import {Protocoll} from './Protocoll';
import {Normen} from './Normen';
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";
import CustomDialog, {CustomDialogHeaderType} from "../global/CustomDialog";
import {SystemSettingForm} from "../systemManagement/SystemSettingForm";
import {confirmDialog} from "primereact/confirmdialog";

type Props = {
    data?: Person,
    setVisible: any,
    setIsDirty: (isDirty: boolean) => void
    onFinished: (success: any) => void,
}
export const PersonEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common', 'stammdaten']);

    const userPcIds = getUserFromLocalStorage().betreuuendes_pc_ids;

    const [data, setData] = useState<Person | undefined>(undefined);
    const [height, setHeight] = useState<number>(0);
    const [protocoll0Length, setProtocoll0Length] = useState<number>(0);
    const [protocoll1Length, setProtocoll1Length] = useState<number>(0);
    const [protocoll2Length, setProtocoll2Length] = useState<number>(0);
    const [triggerDoubletten, {data: resultDoubletten, isLoading, isError, isSuccess}] = useLazyGetDoublettenQuery();
    const [showDoublettenDialog, setShowDoublettenDialog] = useState<boolean>(false);
    const [deletePersonQuery] = useDeletePersonMutation();
    const [setFocus, setSetFocus] = useState<boolean>(false);

    const divRef: any = useRef(null);
    const inputRef: any = useRef<any>(null);

    const anredeQuery = useGetAnredeQuery({});
    const titelQuery = useGetTitleQuery({});
    const kritikalitaetQuery = useGetKritikalitaetQuery({});
    const bearbeiterQuery = useGetBearbeiterQuery({});
    // const languageQuery = useGetLanguageQuery({});
    const statusbearbeitungQuery = useGetStatusbearbeitungQuery({});
    const profitcenterQuery = useGetProfitcenterQuery({});
    const customButtons = [
        <Button
            type='button'
            // form={'formLocation'}
            label={t('cancel')}
            className="p-button-text"
            disabled={false}
            onClick={() => {
                setShowDoublettenDialog(false)
            }}
        />,
        <Button
            type='submit'
            //form={'formLocation'}
            label={t('persons:doubletteSave')}
            className="p-button-text"
            disabled={false}
            onClick={() => {
                createPerson(data);
            }}
        />

    ];


    let propsData: any = props.data ? JSON.parse(JSON.stringify(props.data)) : undefined;

    if (propsData && propsData.kompetenzen) {
        for (let i = 0; i < propsData.kompetenzen.length; i++) {
            const komp = propsData.kompetenzen[i];

            if (komp.erfahrungsaustausch) {
                komp.erfahrungsaustausch = new Date(komp.erfahrungsaustausch);
            }
            if (komp.erfahrungsaustausch_gueltig_bis) {
                komp.erfahrungsaustausch_gueltig_bis = new Date(komp.erfahrungsaustausch_gueltig_bis);
            }
            if (komp.monitoring) {
                komp.monitoring = new Date(komp.monitoring);
            }
            if (komp.monitoring_gueltig_bis) {
                komp.monitoring_gueltig_bis = new Date(komp.monitoring_gueltig_bis);
            }
            if (komp.berufung_gueltig_bis) {
                komp.berufung_gueltig_bis = new Date(komp.berufung_gueltig_bis);
            }
        }
    }

    let defaultValues: Person = {
        'aktiv': propsData ? propsData.aktiv : true,
        'aquirierte_kunden': propsData ? propsData.aquirierte_kunden : '',
        'anrede_id': propsData ? propsData.anrede_id : '',
        'auditvolumen_vorjahr': propsData ? propsData.auditvolumen_vorjahr : 0,
        'ausschluss_kollege': propsData ? propsData.ausschluss_kollege : '',
        'ausschluss_kunde': propsData ? propsData.ausschluss_kunde : '',
        'bearbeiter_id': propsData ? propsData.bearbeiter_id : '',
        'berufserfahrung': propsData ? propsData.berufserfahrung : '',
        'count_protokoll_0': propsData ? propsData.count_protokoll_0 : 0,
        'count_protokoll_1': propsData ? propsData.count_protokoll_1 : 0,
        'count_protokoll_2': propsData ? propsData.count_protokoll_2 : 0,
        'einsatzbereich': propsData ? propsData.einsatzbereich : '',
        'email': propsData ? propsData.email : '',
        'datum_gespraech': propsData && propsData.datum_gespraech ? new Date(propsData.datum_gespraech) : null,
        'freigabekompetenz': propsData ? propsData.freigabekompetenz : '',
        'geburtsdatum': propsData ? propsData.geburtsdatum : '',
        'gewuenschtes_volumen': propsData ? propsData.gewuenschtes_volumen : '',
        'id': propsData ? propsData.id : '',
        'iaf_codes': propsData ? propsData.iaf_codes : [],
        'kompetenzen': propsData && propsData.kompetenzen ? propsData.kompetenzen : [],
        'kompetenzenFilter': propsData && propsData.kompetenzenFilter ? propsData.kompetenzenFilter : '',
        'kritikalitaet_id': propsData ? propsData.kritikalitaet_id : '',
        'land': propsData ? propsData.land : '',
        'last_import': propsData ? propsData.last_import : null,
        'leistungsfeedback': propsData ? propsData.leistungsfeedback : 0,
        'name': propsData ? propsData.name : '',
        'normen': propsData ? propsData.normen : '',
        'ort': propsData ? propsData.ort : '',
        'kritikalitaet_info': propsData ? propsData.kritikalitaet_info : '',
        'person_nr': propsData ? propsData.person_nr : null,
        'plz': propsData ? propsData.plz : '',
        'betreuuendes_pc_id': propsData ? propsData.betreuuendes_pc_id : '',
        'qualifikation_zusaetzlich': propsData ? propsData.qualifikation_zusaetzlich : '',
        'scopes': propsData ? propsData.scopes : '',
        'language': propsData ? propsData.language : '',
        // 'sprache': propsData && propsData.sprache ? propsData.sprache : [],
        'status_id': propsData ? propsData.status_id : '',
        'strasse_hnr': propsData ? propsData.strasse_hnr : '',
        'telefon': propsData ? propsData.telefon : '',
        'titel_id': propsData ? propsData.titel_id : '',
        'umsatz_vorjahr': propsData ? propsData.umsatz_vorjahr : 0,
        // 'vorkommnisse': propsData ? propsData.vorkommnisse : [],
        'vorname': propsData ? propsData.vorname : '',
        'wettbewerber': propsData && propsData.wettbewerber ? propsData.wettbewerber : '',
        'wiedervorlage_datum': propsData && propsData.wiedervorlage_datum ? new Date(propsData.wiedervorlage_datum) : null,
        'wiedervorlage_aktiv': propsData ? propsData.wiedervorlage_aktiv : false,
    };

    const {
        control,
        formState: {errors, isDirty},
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch
    } = useForm({defaultValues});

    const aktiv = watch('aktiv');
    const personNr = watch('person_nr');
    const anredeId = watch('anrede_id');
    const titelId = watch('titel_id');
    const vorname = watch('vorname');
    const nachname = watch('name');
    const strasseHnr = watch('strasse_hnr');
    const plz = watch('plz');
    const ort = watch('ort');
    const email = watch('email');
    const telefon = watch('telefon');
    const betreuuendesPcId = watch('betreuuendes_pc_id');
    const lastImport = null; // watch('last_import');

    const checkPc = (): boolean => {
        return checkPermission(permissions.isProfitCenter) && betreuuendesPcId === userPcIds;
    }

    const saveData = (data: any, e: any) => {
        setData(data);
    };

    function deepFlattenToObject(obj: any, prefix = '') {
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object' && obj[k] !== null) {
                Object.assign(acc, deepFlattenToObject(obj[k], pre + k));
            } else {
                // @ts-ignore
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});
    }

    const getFormErrorMessage = (name: string) => {
        let err: any = deepFlattenToObject(errors);

        // console.log(err, name);

        // if (err[name + '.message']) {
        //     showMessageOnError(t('error'), err[name + '.message'], 2500);
        // }

        return err[name + '.message'] ? <small className="p-error">{err[name + '.message']}</small> :
            <small className="p-error">&nbsp;</small>;
    };

    useEffect(() => {
        props.setIsDirty(isDirty);
    }, [isDirty]);

    useEffect(() => {
        if (divRef.current) {
            setHeight(divRef.current.parentElement.clientHeight);
        }
    }, [divRef.current]);


    useEffect(() => {
        if (typeof data !== 'undefined') {
            if (props.data) {
                console.log(data)
                updatePerson(data);
            } else {
                triggerDoubletten({vorname: data.vorname, name: data.name}, false);
                // createPerson(data);
            }
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess && resultDoubletten.length > 0) {
            setShowDoublettenDialog(true)
        } else if (isSuccess && resultDoubletten.length == 0) {
            if (data) {
                createPerson(data)
            }

        }
    }, [isSuccess, resultDoubletten]);

    const createPerson = (data: any) => {
        keycloakfetch.post(settings.apiUrl + '/createPerson', data).then(result => {
            //  console.log(result);
            if (!result.error) {
                data.id = result.id;
                showMessageOnSuccess(t('success'), 'Auditor gespeichert!');
                props.onFinished(data);
            } else {
                showMessageOnError(t('error'), result.error);
            }
        });
    };

    const updatePerson = (data: any) => {
        keycloakfetch.put(settings.apiUrl + '/updatePerson', data).then(result => {
            //console.log(result);
            if (!result.error) {
                showMessageOnSuccess(t('success'), 'Auditor gespeichert!');
                props.onFinished(data);
            } else {
                showMessageOnError(t('error'), result.error);
            }
        });
    };

    const getDropdownOptions = (query: any) => {
        const result = query.data ? query.data.map((item: any) => {
            return {value: item.id, label: item.name};
        }) : [];

        return result;
    };

    useEffect(() => {
        if (inputRef.current && setFocus) {
            setSetFocus(false);
            inputRef.current.childNodes[0].childNodes[0].childNodes[0].focus();
        }
    }, [inputRef.current, setFocus]);

    const getActive = (value: any) => {
        return value ? t('active') : t('inactive');
    }

    const getOverviewValue = (value: any) => {
        // @ts-ignore
        let result = value;

        if (result == null || result == 'null') {
            result = '';
        }

        return result;
    };

    const getOverviewValueAnrede = () => {
        let result = anredeId;

        if (anredeQuery.data) {
            for (let i = 0; i < anredeQuery.data.length; i++) {
                if (anredeQuery.data[i].id === result) {
                    result = anredeQuery.data[i].name;
                    break;
                }
            }

            if (!result) {
                result = '';
            }
        }

        return result;
    };

    const getOverviewValueTitel = () => {
        let result = titelId;

        if (titelQuery.data) {
            for (let i = 0; i < titelQuery.data.length; i++) {
                if (titelQuery.data[i].id === result) {
                    result = titelQuery.data[i].name;
                    break;
                }
            }

            if (!result) {
                result = '';
            }
        }

        return result;
    };

    const _setProtocoll0Length = (count: number) => {
        setProtocoll0Length(count);
    };

    const _setProtocoll1Length = (count: number) => {
        setProtocoll1Length(count);
    };
    const _setProtocoll2Length = (count: number) => {
        setProtocoll2Length(count);
    };


    return (<div className={'relative h-full'}>
            <form ref={divRef} id="editParcelForm" onSubmit={handleSubmit(saveData)} className={'h-full'}>
                <FormPrompt hasUnsavedChanges={isDirty}/>
                <EditDialogLayout left={
                    <div>
                        <Accordion activeIndex={0}>
                            <AccordionTab className={'accordion-header-1'}
                                          header={<div className={'flex'}><FontAwesomeIcon
                                              className='sidebar-icon mx-2' icon={faEye}/>Übersicht
                                          </div>}>
                                <div className={'grid'}>
                                    {/*  <div className={'col-fixed py-0 mt-2'}>
                                        <StatusLight id={'status-person'} value={getValues('ampel')} info={''}
                                                     light={false}
                                                     horizontal={false} scale={1.2}/>
                                    </div>*/}
                                    <div className={'col py-0 mt-2'}>
                                        <b><p>{getOverviewValue(personNr) + ' / ' + getActive(aktiv)}</p></b>
                                        <p>{`${getOverviewValueAnrede()} ${getOverviewValueTitel()} ${getOverviewValue(vorname)} ${getOverviewValue(nachname)}`}</p>
                                        <p>{`${getOverviewValue(strasseHnr)}`}<br/>{`${getOverviewValue(plz)} ${getOverviewValue(ort)}`}
                                        </p>
                                        <div>
                                            <a
                                                href={'mailto:' + getOverviewValue(email)}>{`${getOverviewValue(email)}`}</a>
                                        </div>
                                        <div><a
                                            href={'tel:' + getOverviewValue(telefon)}>{getOverviewValue(telefon)}</a>
                                        </div>
                                    </div>
                                    <div className={'col-6 py-0 mt-2 relative'}>
                                        {lastImport &&
                                            <p className={'absolute'} style={{
                                                bottom: 0,
                                                right: 0
                                            }}>{`SAP Import: ${moment(lastImport).format('DD.MM.yyyy HH:mm')}`}</p>}
                                    </div>
                                    <div>
                                        <div className={'col py-0 mt-2'}>
                                            {checkPermission(permissions.isAdmin) &&
                                                <Button
                                                    className={'p-button-rounded p-button-danger p-button-text mt-auto'}
                                                    style={{float: 'right'}}
                                                    type={'button'}
                                                    icon={'pi pi-trash'}
                                                    disabled={!checkPermission(permissions.isAdmin)}
                                                    onClick={() => {
                                                        confirmDialog({
                                                            header: t('persons:dialogs.deletePersonHeader'),
                                                            message: t('persons:dialogs.deletePersonMessage'),
                                                            icon: 'pi pi-exclamation-triangle',
                                                            acceptLabel: t('yes'),
                                                            rejectLabel: t('no'),
                                                            accept: () => {
                                                                deletePersonQuery({
                                                                    person_id: getValues('id'),
                                                                }).then((result: any) => {
                                                                    if (!result.error) {
                                                                        showMessageOnSuccess('Erfolg', 'Auditor erfolgreich gelöscht');
                                                                        props.onFinished(result);
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }}/>}
                                        </div>
                                    </div>
                                </div>

                            </AccordionTab>
                        </Accordion>
                        <br/>
                        <Accordion activeIndex={!props.data ? 0 : undefined}>
                            <AccordionTab className={'accordion-header-1'}
                                          header={<div className={'flex'}><FontAwesomeIcon
                                              className='sidebar-icon mx-2' icon={faTrafficLight}/>Bearbeitungsstatus
                                          </div>}>
                                <div className={'grid'}>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.status_id')}
                                            name={'status_id'}
                                            type={'dropdown'}
                                            dropdownOptions={getDropdownOptions(statusbearbeitungQuery)}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.datum_gespraech')}
                                            name={'datum_gespraech'}
                                            type={'date'}
                                            showTime={false}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            className={'mb-2'}
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.aktiv')}
                                            name={'aktiv'}
                                            type={'checkbox'}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.bearbeiter_id')}
                                            name={'bearbeiter_id'}
                                            type={'dropdown'}
                                            dropdownOptions={getDropdownOptions(bearbeiterQuery)}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin)}
                                            label={t('persons:columns.betreuuendes_pc_id')}
                                            name={'betreuuendes_pc_id'}
                                            type={'dropdown'}
                                            dropdownOptions={getDropdownOptions(profitcenterQuery)}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={true}
                                            label={t('persons:columns.wiedervorlage_datum')}
                                            name={'wiedervorlage_datum'}
                                            type={'date'}
                                            showTime={false}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-8 py-0'}>
                                        <Input
                                            edit={true}
                                            label={t('persons:columns.wiedervorlage_aktiv')}
                                            name={'wiedervorlage_aktiv'}
                                            type={'checkbox'}
                                            validationControl={control}
                                            validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <br/>
                        <Accordion activeIndex={!props.data ? 0 : undefined}>
                            <AccordionTab className={'accordion-header-2'}
                                          header={<div><FontAwesomeIcon className='sidebar-icon mx-2' icon={faUser}/>Personen-
                                              und Kontaktdaten</div>}>
                                <div className={'grid'}>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.person_nr')}
                                            name={'person_nr'}
                                            type={'number'}
                                            useGrouping={false}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{
                                                required: t('persons:columns.person_nr') + ' ' + t('input:required')
                                            }}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.anrede_id')}
                                            name={'anrede_id'}
                                            type={'dropdown'}
                                            dropdownOptions={getDropdownOptions(anredeQuery)}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.titel_id')}
                                            name={'titel_id'}
                                            type={'dropdown'}
                                            dropdownOptions={getDropdownOptions(titelQuery)}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null &&(checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.vorname')}
                                            name={'vorname'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: t('persons:columns.vorname') + ' ' + t('input:required')}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.name')}
                                            name={'name'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: t('persons:columns.name') + ' ' + t('input:required'),}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.geburtsdatum')}
                                            name={'geburtsdatum'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.strasse_hnr')}
                                            name={'strasse_hnr'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.plz')}
                                            name={'plz'}
                                            useGrouping={false}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.ort')}
                                            name={'ort'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.land')}
                                            name={'land'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.telefon')}
                                            name={'telefon'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-4 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.email')}
                                            name={'email'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>

                                    <div className={'col-12 md:col-4 py-0'}>
                                    </div>

                                </div>
                            </AccordionTab>
                        </Accordion>
                        <br/>
                        <Accordion activeIndex={!props.data ? 0 : undefined}>
                            <AccordionTab className={'accordion-header-3'}
                                          header={<div><FontAwesomeIcon className='sidebar-icon mx-2' icon={faBook}/>Kompetenzen
                                          </div>}>
                                <div className={'grid'}>
                                    <div className={'col-12 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.einsatzbereich')}
                                            name={'einsatzbereich'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    {/*<div className={'col-12 py-0'}>*/}
                                    {/*    <Input*/}
                                    {/*        edit={checkPermission(permissions.isAdmin) || checkPc()}*/}
                                    {/*        label={t('persons:columns.sprache')}*/}
                                    {/*        name={'sprache'}*/}
                                    {/*        disabled={lastImport != null}*/}
                                    {/*        type={'multiselect'}*/}
                                    {/*        dropdownOptions={getDropdownOptions(languageQuery)}*/}
                                    {/*        validationControl={control} validationErrorMessage={getFormErrorMessage}*/}
                                    {/*        validationRules={{required: false,}}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className={'col-12 py-0'}>
                                        <Input
                                            edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}
                                            label={t('persons:columns.language')}
                                            name={'language'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.qualifikation_zusaetzlich')}
                                            name={'qualifikation_zusaetzlich'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.berufserfahrung')}
                                            name={'berufserfahrung'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.aquirierte_kunden')}
                                            name={'aquirierte_kunden'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <br/>
                        <Accordion activeIndex={!props.data ? 0 : undefined}>
                            <AccordionTab className={'accordion-header-4'}
                                          header={<div><FontAwesomeIcon className='sidebar-icon mx-2'
                                                                        icon={faHandshake}/>Zusammenarbeit</div>}>
                                <div className={'grid'}>
                                    <div className={'col-12 md:col-6 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.gewuenschtes_volumen')}
                                            name={'gewuenschtes_volumen'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-6 py-0 hidden'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.auditvolumen_vorjahr')}
                                            name={'auditvolumen_vorjahr'}
                                            type={'number'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-6 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.umsatz_vorjahr')}
                                            name={'umsatz_vorjahr'}
                                            type={'number'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-6 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPermission(permissions.isProfitCenter)}
                                            label={t('persons:columns.ausschluss_kollege')}
                                            name={'ausschluss_kollege'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-6 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPermission(permissions.isProfitCenter)}
                                            label={t('persons:columns.ausschluss_kunde')}
                                            name={'ausschluss_kunde'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-6 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.kritikalitaet')}
                                            name={'kritikalitaet_id'}
                                            type={'dropdown'}
                                            dropdownOptions={getDropdownOptions(kritikalitaetQuery)}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 md:col-6 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.kritikalitaet_info')}
                                            name={'kritikalitaet_info'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                    <div className={'col-12 py-0'}>
                                        <Input
                                            edit={checkPermission(permissions.isAdmin) || checkPc()}
                                            label={t('persons:columns.leistungsfeedback')}
                                            name={'leistungsfeedback'}
                                            type={'textarea'}
                                            validationControl={control} validationErrorMessage={getFormErrorMessage}
                                            validationRules={{required: false,}}
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <br/>

                    </div>
                } right={<div>
                    <TabView>
                        <TabPanel header={'Allgemein'}>
                            <div className='grid p-3'>
                                {props.data && <div className='col-12'>
                                    <FileManagement person_id={props.data ? props.data.id : ''}
                                                    edit={checkPermission(permissions.isAdmin) || checkPc()}/>
                                    <br/>
                                </div>}
                                <div className='col-12'>
                                    <Input
                                        edit={checkPermission(permissions.isAdmin) || checkPc()}
                                        label={t('persons:columns.wettbewerber_id')}
                                        name={'wettbewerber'}
                                        type={'textarea'}
                                        validationControl={control} validationErrorMessage={getFormErrorMessage}
                                        validationRules={{required: false,}}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header={'Normen'}>
                            <div className={'p-3'}>
                                <Normen t={t} getFormErrorMessage={getFormErrorMessage} form={{
                                    control,
                                    formState: {errors},
                                    handleSubmit,
                                    setValue,
                                    getValues,
                                    watch
                                }}
                                        edit={lastImport == null && (checkPermission(permissions.isAdmin) || checkPc())}/>
                            </div>
                        </TabPanel>
                        {props.data &&
                            <TabPanel header={`Protokoll (${protocoll0Length || getValues('count_protokoll_0')})`}>
                                <div className={'p-3'}>
                                    <Protocoll person_id={props.data.id} type={0} getCount={_setProtocoll0Length}/>
                                </div>
                            </TabPanel>}
                        {props.data &&
                            <TabPanel header={`Kundenfeedback (${protocoll1Length || getValues('count_protokoll_1')})`}>
                                <div className='p-3'>
                                    <Protocoll person_id={props.data.id} type={1} getCount={_setProtocoll1Length}/>
                                </div>
                            </TabPanel>}
                        {props.data &&
                            <TabPanel header={`Vorkommnisse (${protocoll2Length || getValues('count_protokoll_2')})`}>
                                <div className='p-3'>
                                    <Protocoll person_id={props.data.id} type={2} getCount={_setProtocoll2Length}/>
                                </div>
                            </TabPanel>}
                    </TabView>
                </div>}/>
            </form>
            <CustomDialog
                onHide={() => {
                    setShowDoublettenDialog(false)
                }}
                visible={showDoublettenDialog}
                header={t('persons:doubletteHeader')}
                headerType={CustomDialogHeaderType.Create}
                onCancel={() => setShowDoublettenDialog(false)}
                style={{width: '25vw'}}
                customButtons={customButtons}
            >
                <table className={'protocoll-table w-full'}>
                    <tbody>
                    <tr>
                        <th>Vorname</th>
                        {/*<th>Datum</th>*/}
                        <th>Nachname</th>
                        <th>Adresse</th>
                        <th>Anzahl Vorkommnisse</th>
                    </tr>
                    {resultDoubletten && resultDoubletten.map((item: any, index: number) => {
                        return (<tr key={index}>
                            <td>{item.vorname}</td>
                            <td>{item.nachname}</td>
                            {/*<td>{moment(item.datum).format(t('dateformat'))}</td>*/}
                            <td>{item.strasse_hnr} {item.plz} {item.ort}</td>
                            <td>{item.count} </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </CustomDialog>
        </div>

    );
};
